@use 'fonts';

@font-face {
  font-family: fonts.$family-head;
  src: url('./fonts/ArchivoBlack-Regular.ttf');
}

@font-face {
  font-family: fonts.$family-body-dmsans;
  src: url('./fonts/DMSans-VariableFont_opsz,wght.ttf');
}

@font-face {
  font-family: fonts.$family-body;
  src: url('./fonts/NotoSansSC-Regular.ttf');
}

@font-face {
  font-family: fonts.$family-body-bold;
  src: url('./fonts/NotoSansSC-Bold.ttf');
}

@mixin text-options(
  $name,
  $font-family,
  $font-size,
  $line-height,
  $font-weight
) {
  .#{$name} {
    margin: unset;
    font-family: $font-family;
    font-size: $font-size;
    line-height: $line-height;
    font-weight: $font-weight;
  }
}

.bold {
  font-weight: 700 !important;
}

// Example usage with responsive font sizes

@include text-options('body1', fonts.$family-body, 18px, auto, 400);
@include text-options('body2', fonts.$family-body, 16px, 1.4rem, 400);
@include text-options('body3', fonts.$family-body, 14px, 1.4rem, 400);
@include text-options('body4', fonts.$family-body, 12px, 1.4rem, 400);
@include text-options('body5', fonts.$family-body, 10px, auto, 400);
@include text-options('body6', fonts.$family-body, 56px, 60px, 700);
@include text-options('body7', fonts.$family-body, 20px, normal, 700);
@include text-options('body8', fonts.$family-body, 20px, auto, 400);
@include text-options('body9', fonts.$family-body-bold, 40px, auto, 700);
@include text-options('body10', fonts.$family-body, 30px, auto, 700);
@include text-options('body11', fonts.$family-body, 36px, auto, 700);
@include text-options('body12', fonts.$family-body, 24px, auto, 700);
@include text-options('body13', fonts.$family-body, 24px, auto, 400);
@include text-options('bodybold1', fonts.$family-body-bold, 20px, auto, 700);
@include text-options('bodybold2', fonts.$family-body-bold, 20px, auto, 700);
@include text-options('bodybold3', fonts.$family-body-bold, 36px, auto, 700);
@include text-options('bodybold4', fonts.$family-body-bold, 30px, auto, 700);
@include text-options('bodybold5', fonts.$family-body-bold, 16px, auto, 700);
@include text-options('bodybold6', fonts.$family-body-bold, 24px, auto, 700);
@include text-options('bodybold7', fonts.$family-body-bold, 40px, auto, 700);
@include text-options('bodybold8', fonts.$family-body-bold, 14px, auto, 700);
@include text-options('bodybold9', fonts.$family-body-bold, 18px, auto, 700);
@include text-options('bodybold10', fonts.$family-body-bold, 66px, auto, 700);
@include text-options('bodybold11', fonts.$family-body-bold, 54px, auto, 700);

@include text-options('heading0', fonts.$family-head, 50px, 2rem, 400);
@include text-options('heading1', fonts.$family-head, 30px, auto, 400);
@include text-options('heading2', fonts.$family-head, 24px, auto, 400);
@include text-options('heading3', fonts.$family-head, 20px, auto, 400);
@include text-options('heading4', fonts.$family-head, 16px, 1.5rem, 400);
@include text-options('heading5', fonts.$family-head, 14px, 1rem, 400);
@include text-options('heading6', fonts.$family-head, 56px, 60px, 400);
@include text-options('heading7', fonts.$family-head, 20px, normal, 400);
@include text-options('heading8', fonts.$family-head, 40px, 4rem, 400);
@include text-options('heading9', fonts.$family-head, 42px, 4rem, 400);
@include text-options('heading10', fonts.$family-head, 32px, 4rem, 400);

@include text-options(
  'bodydmsans1',
  fonts.$family-body-dmsans,
  16px,
  auto,
  400
);
@include text-options(
  'bodydmsans2',
  fonts.$family-body-dmsans,
  18px,
  auto,
  400
);
@include text-options(
  'bodydmsans3',
  fonts.$family-body-dmsans,
  12px,
  auto,
  400
);
@include text-options(
  'bodydmsans4',
  fonts.$family-body-dmsans,
  18px,
  auto,
  700
);
@include text-options(
  'bodydmsans5',
  fonts.$family-body-dmsans,
  14px,
  auto,
  700
);
@include text-options(
  'bodydmsans6',
  fonts.$family-body-dmsans,
  14px,
  auto,
  400
);
@include text-options(
  'bodydmsans7',
  fonts.$family-body-dmsans,
  16px,
  auto,
  700
);
