html,
body,
#__next {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 62.5%;
  background: var(--Main-Primary-Varient, #272935);

  // overflow-x: hidden;
}
