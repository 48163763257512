@use 'styles/common/colors'; // Assuming you have color variables defined

.container {
  transition: all 1s ease;
  overflow: hidden;
}

.headerContainer {
  display: flex;
  justify-content: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 5%;
  // padding-top: 32px;
  // padding-bottom: 32px;
  background-color: transparent; // Ensure the background is transparent
  height: 100px; // Set initial height
  border-bottom: transparent;
  color: white;
  max-width: 1080px;
  width: 100%;

  @media (max-width: 835px) {
    background: var(--Main-Primary, #272935);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    padding: 0px 32px;
  }
}

.transparent {
  background-color: transparent !important; // Ensure the background is transparent
  transition: background-color 0.5s ease-in-out;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.normalHeader {
  background-color: #272935;
  transition: background-color 0.5s ease-in-out;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

  @media screen and (max-width: 835px) {
    background-color: #272935;
  }
}

.opened {
  @media screen and (max-width: 400px) {
    min-height: 100vh;
  }
  @media screen and (min-width: 401px) and (max-width: 835px) {
    min-height: 80vh;
  }
}

.nav {
  display: flex;
  height: 108px;
  align-items: center;
  gap: 24px;
  color: var(--Main-BG, #f2f3f7);
  text-align: center;

  /* English (Mobile)/Heading 4 */
  font-family: 'Archivo Black';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 835px) {
    display: none;
  }

  @media (max-width: 950px) {
    gap: 0;
  }
}

.menu {
  border-radius: 20px;
  background: var(--Main-Primary-Varient, #272935);
  border: transparent;
  margin-top: 22px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  /* Glassmorphism/BG Blur 1 */
  backdrop-filter: blur(10px);
  display: flex;
  padding: 20px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.menuItem:hover {
  background-color: transparent;
  color: #4178fa;
}

.nav .link {
  position: relative;
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav .link.active {
  color: #4178fa;
}

.nav .link.active::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 2px;
  width: 50%;
  height: 2px;
  background-color: #4178fa;
  margin-left: 10px;
}

.hamburger {
  color: white;
  @media screen and (min-width: 836px) {
    display: none;
  }
}

.headerLogo {
  width: 157px;
  height: 35px;
  @media (max-width: 835px) {
    width: 133.125px;
    height: 30px;
  }
}

.linkMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 42px;
  z-index: 100;
  background: var(--Main-Primary, #272935);
  @media screen and (min-width: 836px) {
    display: none;
  }
}

.linkClicked {
  color: var(--Main-Secondary, #4178fa);
  margin-bottom: 30px;

  @media (max-width: 835px) {
    text-underline-offset: 3px;
  }
  // font-size: 20px;
  // font-style: normal;
  // font-weight: 400;
}

.linkUnclicked {
  color: var(--Main-BG, #f2f3f7);
  margin-bottom: 30px;

  // font-size: 20px;
  // font-style: normal;
  // font-weight: 400;
  // line-height: normal;
  text-decoration-line: none;
}

.languageClicked {
  color: var(--Main-Secondary, #4178fa);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
}

.languageUnclicked {
  color: var(--Main-BG, #f2f3f7);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
}
