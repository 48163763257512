.outerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding-inline: 4%;
  background: var(--Main-Primary, #272935);
  color: #f2f3f7;
  width: 100%;
  // width: 1064px;

  justify-content: center;
  position: relative;
  padding: 50px 0px;
  margin-bottom: -5px;
}

.innerContainer {
  width: 1064px;
}

.contentFooter {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 56px;
}

.groupFooter {
  width: 1080px;
  align-items: center;
}

.footerMobile {
  padding: 50px 32px 87px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
  background: var(--Main-Primary, #272935);
  @media (max-width: 835px) {
    padding: 50px 6px 87px 6px;
    margin-top: -3px;
  }
  // @media (max-width: 380px) {
  //   padding: 50px 6px 87px 6px;
  // }
}
